import { styled } from '@f8n-frontend/stitches';
import { ComponentProps, VariantProps } from '@stitches/react';

type TextProps = ComponentProps<typeof Text>;
type TextVariants = VariantProps<typeof Text>;

const Text = styled('div', {
  fontFamily: '$body',
  transition: '$1 $ease color',

  '& > strong': {
    fontWeight: 'inherit',
  },

  variants: {
    size: {
      0: { fontSize: '$0', letterSpacing: '$0' },
      1: { fontSize: '$1', letterSpacing: '$0' },
      2: { fontSize: '$2', letterSpacing: '$0' },
      3: { fontSize: '$3', letterSpacing: '$0' },
      4: { fontSize: '$4', letterSpacing: '$-1' },
      5: { fontSize: '$5', letterSpacing: '$-2' },
      6: { fontSize: '$6', letterSpacing: '$-2' },
      7: { fontSize: '$7', letterSpacing: '$-2' },
      8: { fontSize: '$8', letterSpacing: '$-2' },
      9: { fontSize: '$9', letterSpacing: '$-3' },
      10: { fontSize: '$10', letterSpacing: '$-3' },
    },
    lineHeight: {
      0: { lineHeight: '$0' },
      1: { lineHeight: '$1' },
      2: { lineHeight: '$2' },
      3: { lineHeight: '$3' },
    },
    weight: {
      regular: {
        fontWeight: '$regular',
        '& > strong': {
          fontWeight: '$semibold',
        },
      },
      medium: { fontWeight: '$medium' },
      semibold: { fontWeight: '$semibold' },
      bold: { fontWeight: '$bold' },
    },
    color: {
      error: {
        color: '$red3',
      },
      strong: {
        color: '$black100',
      },
      dim: {
        color: '$black60',

        '& > strong': {
          color: '$black100',
        },
      },
      upcoming: {
        color: '$upcoming',
      },
      available: {
        color: '$available',
      },
      live: {
        color: '$live',
      },
      unavailable: {
        color: '$unavailable',
      },
    },
    uppercase: {
      true: {
        textTransform: 'uppercase',
      },
    },
    ellipsis: {
      true: {
        truncate: true,
      },
    },
  },
});

export type { TextProps, TextVariants };
export default Text;
